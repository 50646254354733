import { v4 as uuidv4 } from 'uuid';

export class ProductionLineEntity {
    constructor(id, bioGspp, changeDate, compartmentName, contractNumber, createdBy, createDate, crop, cropFamily,
        cropFamilyId, deliverDate, density, endDate, femalePlantsMx, femalePlantsNl, femalePld, growthTech, lastTag,
        location, malePerFemaleRatio, malePlants, malePlantsMx, malePld, malesNeeded, ms, msVariety, nextTag, noRows, occupancy,
        orderDate, orderId, orderKg, orderYear, originalOrderKg, parentId, parentalFemale, parentalFemaleId, parentalFemaleName,
        parentalMale, parentalMaleId, parentalMaleName, phase, plantsPerLine, priority, processStageId, processStageTitle,
        productionArea, productionLineOccupancy, realOrderDate, remarkStockseedFemale, remarkStockseedMale, remarks,
        rowsRequired, season, sequence, startDate, status, surfaceRequired, tag, totalPlants, type, ultimateShipmentDate,
        updatedBy, updatedDate, variety, varietyId, warning, yieldCalculated, yieldNl, yieldUnits, yieldMx, productionLineMs) {

        this.id = id;
        this.bioGspp = bioGspp;
        this.changeDate = changeDate;
        this.compartmentName = compartmentName;
        this.contractNumber = contractNumber;
        this.createdBy = createdBy;
        this.createDate = createDate;
        this.crop = crop;
        this.cropFamily = cropFamily;
        this.cropFamilyId = cropFamilyId;
        this.deliverDate = deliverDate;
        this.density = density;
        this.endDate = endDate;
        this.femalePlantsMx = femalePlantsMx;
        this.femalePlantsNl = femalePlantsNl;
        this.femalePld = femalePld;
        this.growthTech = growthTech;
        this.lastTag = lastTag;
        this.location = location;
        this.malePerFemaleRatio = malePerFemaleRatio;
        this.malePlants = malePlants;
        this.malePlantsMx = malePlantsMx;
        this.malePld = malePld;
        this.malesNeeded = malesNeeded;
        this.ms = ms;
        this.msVariety = msVariety;
        this.nextTag = nextTag;
        this.noRows = noRows;
        this.occupancy = occupancy;
        this.orderDate = orderDate;
        this.orderId = orderId;
        this.orderKg = orderKg;
        this.orderYear = orderYear;
        this.originalOrderKg = originalOrderKg;
        this.parentId = parentId;
        this.parentalFemale = parentalFemale;
        this.parentalFemaleId = parentalFemaleId;
        this.parentalFemaleName = parentalFemaleName;
        this.parentalMale = parentalMale;
        this.parentalMaleId = parentalMaleId;
        this.parentalMaleName = parentalMaleName;
        this.phase = phase;
        this.plantsPerLine = plantsPerLine;
        this.priority = priority;
        this.processStageId = processStageId;
        this.processStageTitle = processStageTitle;
        this.productionArea = productionArea;
        this.productionLineOccupancy = productionLineOccupancy;
        this.realOrderDate = realOrderDate;
        this.remarkStockseedFemale = remarkStockseedFemale;
        this.remarkStockseedMale = remarkStockseedMale;
        this.remarks = remarks;
        this.rowsRequired = rowsRequired;
        this.season = season;
        this.sequence = sequence;
        this.startDate = startDate;
        this.status = status;
        this.surfaceRequired = surfaceRequired;
        this.tag = tag;
        this.totalPlants = totalPlants;
        this.type = type;
        this.ultimateShipmentDate = ultimateShipmentDate;
        this.updatedBy = updatedBy;
        this.updatedDate = updatedDate;
        this.variety = variety;
        this.varietyId = varietyId;
        this.warning = warning;
        this.yieldCalculated = yieldCalculated;
        this.yieldNl = yieldNl;
        this.yieldUnits = yieldUnits;
        this.yieldMx = yieldMx;
        this.productionLineMs = productionLineMs;

        this.guid = uuidv4();
    }
    static create() {
        return new ProductionLineEntity(0, '', null, '', '',
            null, null, '', '', null, null, null, null, null,
            null, '', '',  '', '', null,
            null, null, '', null, '', null, null, null, null,
            null, '', null, null, null, null, null, null,
            '', null, null, '', null, null,
            null, null, '', '', null, null,
            '', '', '', null, null, null, null,
            null, null, '', null, null, null, null, null,
            null, null, null, null, '', '', '', null);
    }

    static createFromObject(productionLine) {
        return new ProductionLineEntity(productionLine.id, productionLine.bioGspp, productionLine.changeDate, productionLine.compartmentName,
            productionLine.contractNumber, productionLine.createdBy, productionLine.createdDate, productionLine.crop,
            productionLine.cropFamily, productionLine.cropFamilyId, productionLine.deliverDate, productionLine.density,
            productionLine.endDate, productionLine.density, productionLine.femalePlantsMx, productionLine.femalePlantsNl,
            productionLine.femalePld, productionLine.growthTech, productionLine.lastTag, productionLine.location,
            productionLine.malePerFemaleRatio, productionLine.malePlants, productionLine.malePlantsMx, productionLine.malePld,
            productionLine.malesNeeded, productionLine.ms, productionLine.msVariety, productionLine.nextTag, productionLine.noRows, productionLine.occupancy,
            productionLine.orderDate, productionLine.orderId, productionLine.orderKg, productionLine.orderYear,
            productionLine.originalOrderKg, productionLine.parentId, productionLine.parentalFemale, productionLine.parentalFemaleId,
            productionLine.parentalFemaleName, productionLine.parentalMale, productionLine.parentalMaleId,
            productionLine.parentalMaleName, productionLine.phase, productionLine.plantsPerLine, productionLine.priority,
            productionLine.processStageId, productionLine.processStageTitle, productionLine.productionArea,
            productionLine.productionLineOccupancy, productionLine.realOrderDate,
            productionLine.remarkStockseedFemale, productionLine.remarkStockseedMale, productionLine.remarks,
            productionLine.rowsRequired, productionLine.season, productionLine.sequence, productionLine.startDate,
            productionLine.status, productionLine.surfaceRequired, productionLine.tag, productionLine.totalPlants,
            productionLine.type, productionLine.ultimateShipmentDate, productionLine.updatedBy, productionLine.updatedDate,
            productionLine.variety, productionLine.varietyId, productionLine.warning, productionLine.yieldCalculated,
            productionLine.yieldNl, productionLine.yieldUnits, productionLine.yieldMx, productionLine.productionLineMs);
    }
}
