<template>
	<div class="input-group">
		<input ref="datePickerInput" type="text" class="form-control" readonly @input="updateValue" :class="[color, { 'is-invalid': state === false }]" />
		<span class="input-group-addon" @click="showDatePicker"><i class="glyphicon glyphicon-calendar"></i></span>
		<div v-if="state === false" class="invalid-feedback">{{ errorMessage }}</div>
	</div>
</template>

<script>
import $ from 'jquery';
export default {
    props: {
        value: {
            type: String,
            default: ''
        },
        container: {
            type: String,
            default: 'body'
        },
        state: Boolean,
        shouldValidate: {
            type: Boolean,
            default: false
        },
        color: {
            type: String,
            default: 'black'
        }
    },
    mounted() {
        const datePickerInput = $(this.$refs.datePickerInput);
        datePickerInput.val(this.value);

        datePickerInput.datepicker({
            format: 'yyyy-mm-dd',
            autoclose: true,
            orientation: 'top',
            prevText: '<i class="fa fa-chevron-left"></i>',
            nextText: '<i class="fa fa-chevron-right"></i>',
            todayBtn: 'linked',
            container: this.container
        }).on('changeDate', () => {
            this.$emit('input', datePickerInput.val());
        });
    },
    watch: {
        value(newValue) {
            const datePickerInput = $(this.$refs.datePickerInput);
            datePickerInput.datepicker('setDate', newValue);
        }
    },
    methods: {
        showDatePicker() {
            $(this.$refs.datePickerInput).datepicker('show');
        },
        updateValue(event) {
            this.$emit('input', event.target.value);
        }
    },
    computed: {
        errorMessage() {
            return 'Field required';
        }
    }
};
</script>
<style>
	.is-invalid {
		border-color: red !important;
	}

	.real-date {
		color: rgb(0, 109, 123);
	}

	.black {
		color: rgb(103, 106, 108);
	}
</style>

